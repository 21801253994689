import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const AppCircularProgress = () => (
  <CircularProgress variant="indeterminate" disableShrink />
);

export const Loader = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
    >
      <AppCircularProgress />
    </Box>
  );
};
